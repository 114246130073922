import React from 'react';

const SilentAudioWarningModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center">
      <div className="bg-dark text-white p-6 rounded-lg max-w-md mx-auto">
        <h2 className="text-xl font-bold mb-4">Silent Audio Detected</h2>
        <p className="mb-4">
          It seems that the audio input was mostly silent. Please check your microphone settings or try speaking louder.
        </p>
        <p className="mb-4">
          After closing this message, you can start recording again by clicking the microphone button.
        </p>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SilentAudioWarningModal;