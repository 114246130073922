import React, { useState } from 'react';
import './MentorReport.css'; // Make sure this file contains the provided CSS
import DOMPurify from 'dompurify';
import ReactMarkdown from 'react-markdown';

const MentorReport = ({ conversationId, personaType, personaName, user, userName, conversationHistory,conversation_structure_text }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [reportContent, setReportContent] = useState('');
  const [bodyLanguageAnalysis, setBodyLanguageAnalysis] = useState('');
  const [error, setError] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [openSections, setOpenSections] = useState({ mentorFeedback: false, bodyLanguage: false });

  const generateReport = async () => {
    setIsGenerating(true);
    setError('');

    try {
      console.log('Sending request to generate mentor report...');
      const response = await fetch('https://www.nump0.com/persona/generate_mentor_report_react', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ 
          conversation_id: conversationId,
          persona_type: personaType,
          persona_name: personaName,
          conversation_history: conversationHistory,
          conversation_structure_text: conversation_structure_text
        }),
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received data:', data);

      setReportContent(data.mentor_report);
      setBodyLanguageAnalysis(data.body_language_analysis);

      // Generate PDF
      const pdfResponse = await fetch('https://www.nump0.com/persona/download_mentor_report_react', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ 
          mentor_report_html: data.mentor_report,
          body_language_analysis: data.body_language_analysis,
          conversation_id: conversationId,
          user: user,
          user_name: userName,
          conversation_history: conversationHistory
        }),
      });

      if (!pdfResponse.ok) {
        const pdfErrorData = await pdfResponse.json();
        throw new Error(pdfErrorData.error || 'Failed to generate PDF');
      }

      const pdfData = await pdfResponse.json();
      if (pdfData.token) {
        setDownloadUrl(`https://www.nump0.com/persona/get_mentor_report_react/${pdfData.token}`);
      } else {
        throw new Error('No token received for PDF download');
      }

    } catch (error) {
      console.error('Error in generateReport:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsGenerating(false);
    }
  };

  const toggleSection = (section) => {
    setOpenSections(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };


  return (
    <div className="mentor-report">
      <button 
        onClick={generateReport} 
        disabled={isGenerating}
        className={`generate-button ${isGenerating ? 'pulsing' : ''}`}
      >
        {isGenerating ? 'Generating Report...' : 'Press to Generate ATLAS report'}
      </button>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {reportContent && (
        <div className="report-content">
          <h2 className="report-title">ATLAS Report</h2>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(reportContent) }} />
        </div>
      )}

      {downloadUrl && (
        <a 
          href={downloadUrl} 
          download="mentor_report.pdf"
          className="download-button"
        >
          Download Report PDF
        </a>
      )}
    </div>
  );
};

export default MentorReport;
