import React, { useState } from 'react';

const ImageSlideshow = ({ images, personaName }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slideshow-container" style={{ maxWidth: '600px', position: 'relative' }}>
      <div className="med-slides relative">
        <img
          src={`/images/personas/${personaName.toLowerCase()}/meds/med${images[currentIndex]}.png`}
          alt={`Medical image ${currentIndex + 1}`}
          className="slide-image w-full"
        />
        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-70 text-white p-3 text-lg rounded-full hover:bg-opacity-90"
          style={{ fontSize: '24px', width: '40px', height: '40px' }}
        >
          &#10094;
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-70 text-white p-3 text-lg rounded-full hover:bg-opacity-90"
          style={{ fontSize: '24px', width: '40px', height: '40px' }}
        >
          &#10095;
        </button>
      </div>
      <div className="caption-container mt-2">
        <h4 className="text-center">Image {currentIndex + 1} of {images.length}</h4>
      </div>
    </div>
  );
};

export default ImageSlideshow;