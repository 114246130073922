import React, { useState, useCallback, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportIcon from '@mui/icons-material/Assessment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LiveTvIcon from '@mui/icons-material/LiveTv';

const spinKeyframes = `
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinningArc = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    style={{
      position: 'absolute',
      top: '0px',
      left: '0px',
      animation: 'spin 2s linear infinite',
      pointerEvents: 'none',
    }}
  >
    <circle
      cx="29"
      cy="29"
      r="26"
      fill="none"
      stroke="white"
      strokeWidth="0.5"
      strokeDasharray="40 160"
    />
  </svg>
);

export default function NavigationSpeedDial({ onSelect, showMedilist, showEvaluation, med_images }) {
  const [open, setOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(true);
  const speedDialRef = useRef(null);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSpeedDialClick = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleActionClick = useCallback((actionId) => {
    onSelect(actionId);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  }, [onSelect]);

  const handleClickOutside = useCallback((event) => {
    if (speedDialRef.current && !speedDialRef.current.contains(event.target)) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, handleClickOutside]);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = spinKeyframes;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setIsSpinning(true);
    } else {
      setIsSpinning(false);
    }
  }, [open]);

  const actions = [
    { icon: <InfoIcon sx={{ color: 'white' }} />, name: 'Instructions', id: 'instructions' },
    { icon: <DescriptionIcon sx={{ color: 'white' }} />, name: 'Reference', id: 'context' },
    { icon: <HistoryIcon sx={{ color: 'white' }} />, name: 'Transcript', id: 'history' },
    // Only include the Images action if med_images > 0
    ...(med_images > 0 ? [{ icon: <ImageIcon sx={{ color: 'white' }} />, name: 'Images', id: 'images' }] : []),
    { icon: <ReportIcon sx={{ color: 'white' }} />, name: 'Mentor Report', id: 'mentor' },
    { icon: <LiveTvIcon sx={{ color: 'white' }} />, name: 'Live', id: 'live' },
    { icon: <DashboardIcon sx={{ color: 'pink' }} />, name: 'Dashboard', id: 'Dashboard' },
  ];

  if (showMedilist) {
    actions.push({ icon: <ListAltIcon sx={{ color: 'white' }} />, name: 'Medilist', id: 'medilist' });
  }

  if (showEvaluation) {
    actions.push({ icon: <AssignmentTurnedInIcon sx={{ color: 'white' }} />, name: 'Evaluation', id: 'evaluation' });
  }

  return (
    <Box
      ref={speedDialRef}
      sx={{
        position: 'fixed',
        top: 16,
        right: 6,
        '& .MuiSpeedDial-actions': {
          paddingTop: '8px',
        },
      }}
    >
      <SpeedDial
        ariaLabel="Navigation SpeedDial"
        icon={<SpeedDialIcon />}
        direction="down"
        open={open}
        onClick={handleSpeedDialClick}
        FabProps={{
          onMouseEnter: handleOpen,
        }}
        sx={{
          '& .MuiSpeedDial-fab': {
            backgroundColor: 'rgb(65, 105, 225, 0.7)',
            color: 'white',
            position: 'relative',
          },
          '& .MuiSpeedDialIcon-icon': {
            color: 'white',
          },
        }}
      >
        {isSpinning && !open && <SpinningArc />}
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleActionClick(action.id)}
            sx={{
              '& .MuiSpeedDialAction-staticTooltipLabel': {
                backgroundColor: '#747474',
                color: 'white',
              },
              '& .MuiSpeedDialAction-fab': {
                backgroundColor: '#747474',
                color: 'white',
              },
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
